<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters"> 
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="supplier_ids"
                  :not-list="false"
                  :options="supplierOptions"
                  :title="$t('Üretici') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="supplier_ids"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_ids"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_ids"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="transaction_ids"
                  :not-list="false"
                  :options="transactionOptions"
                  :title="$t('İşlem Tipi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="transaction_ids"
                ></custom-multi-select>
              </div>
            </div>
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date" 
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date" 
                  >
                  </date-filter>
                </div>                
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pr-0">
                  <button @click="exportWorkmanshipTable" type="button"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.export_to_excel') }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="exportWorkmanshipListPdf" type="button"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.export_to_list') }}
                  </button>
                </div>
              </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12"> 
      <dashboard-box>
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
      <div class="row"> 
        <div
          class="col-12 info-total-area align-items-center d-flex align-content-end"
        >
          <div class="col-2 text-center mr-2"> 
            <span>{{ $t("Toplam Sipariş Miktarı") }}</span><br>
            <span class="total-area-text">
              {{  
                Number(totals.orderAmount).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2">
            <span>{{ $t("Toplam Yükleme Miktarı") }}</span><br>
            <span class="total-area-text">
              {{
                Number(totals.loadingAmount).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2">
            <span>{{ $t("Toplam İşlem Adeti") }}</span> <br>
            <span class="total-area-text">
              {{
                Number(totals.transactionAmount).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2">
            <span>{{ $t("Toplam Birim Fiyatı") }}</span><br>
            <span class="total-area-text">
              {{
                Number(
                  totals.unitPrice
                ).toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-0"> 
            <span>{{ $t("Toplam Tutar") }}</span><br>
            <span class="total-area-text">
              {{
                Number(
                  totals.cost
                ).toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable"; 
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect"; 

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER, 
  EXPORT,
  EXPORT_PDF,
} from "@/core/services/store/report/workmanshipReport.module";

import {
  GET_ITEMS,
  GET_ITEMS as REST_GET_ITEM,
} from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  WORKMANSHIP_REPORT_START_DATE_STORAGE_NAME,
  WORKMANSHIP_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names"; 

export default {
  name: "WorkmanshipReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox, 
    DateFilter,
    CustomMultiSelect,
    ContextMenu, 
  },
  computed: {
    ...mapGetters({ 
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    }, 
    formattedReport() {
      if (
        !this.items ||
        this.items.data === null ||
        this.items.data === undefined
      )
        return []; 
      let temp = []; 
      let self = this;
      this.items.data.forEach((item) => { 
         temp.push({
          order_number: item.order_number,
          order_amount: Number(item.order_amount).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          loading_amount: Number(item.loading_amount).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          supplier_name: item.supplier_name, 
          color:item.color ?? '-',  
          transaction_name: item.transaction_name, 
          transaction_amount: Number(item.transaction_amount).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ), 
          transaction_unit_price: item.transaction_unit_price+' '+item.currency, 
          total_price :Number(item.total_price).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          )+' '+item.currency, 
        });
      });

      this.totals.orderAmount=0;
      this.totals.loadingAmount=0;
      this.totals.transactionAmount=0;
      this.totals.unitPrice=0;
      this.totals.cost=0;
      this.items.data.forEach((item) => {
        self.totals.orderAmount += Number(item.order_amount)
        self.totals.loadingAmount += Number(item.loading_amount)
        self.totals.transactionAmount += Number(item.transaction_amount)
        self.totals.unitPrice += parseFloat(item.transaction_unit_price)   
        self.totals.cost += parseFloat(item.total_price)   
      });

      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      resizeChartValue: null, 
      search: null,
      onClickButton: false,
      paginate: [],      
      team_id: [],
      page: 1,
      perPage: 25,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      reportListUrl: "api/report/workmanship-report", 
      tempFilters: {}, 
      fields: [
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "order_amount",
          label: this.$t("Sipariş Miktarı"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "loading_amount",
          label: this.$t("Yüklenen Adet"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "supplier_name",
          label: this.$t("Tedarikçi"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "color",
          label: this.$t("report.color"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "transaction_name",
          label: this.$t("İşlem Adı"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "transaction_amount",
          label: this.$t("Adet"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "transaction_unit_price",
          label: this.$t("Birim Fiyat"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_price",
          label: this.$t("Tutar"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
      ],  
      supplier_ids: [],
      supplierOptions: [],
      orderOptions:[], 
      order_ids: [], 
      transactionOptions:[], 
      transaction_ids: [], 
      totals:{
        orderAmount:0,
        loadingAmount:0,
        transactionAmount:0,
        unitPrice:0,
        cost:0,
      }
    };
  },
  methods: {
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    beforeFilterResult() {
      setTimeout(() => {
        this.filterResult();
      }, 100);
    },
    exportWorkmanshipTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);
      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      }
      if (this.order_ids) {
        this.$set(filters, "order_ids", JSON.stringify(this.order_ids));
      } 
      if (this.transaction_ids) {
        this.$set(filters, "transaction_ids", JSON.stringify(this.transaction_ids));
      } 
      this.$store.commit(SET_LOADING,true);
 
      this.$store.dispatch(EXPORT, {
        filters: filters
      }).then(result => {
        if(result.status) {
          let fileURL = window.URL.createObjectURL(new Blob([result.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'workmanship' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          this.$store.commit(SET_LOADING,false);
          fileLink.click();
        }
      })  
    },
    exportWorkmanshipListPdf() {
      let filters = {
        start_date: moment(this.selected_start_date).format(LARAVEL_DATE_FORMAT),
        end_date: moment(this.selected_end_date).format(LARAVEL_DATE_FORMAT),
      };
      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      }
      if (this.order_ids) {
        this.$set(filters, "order_ids", JSON.stringify(this.order_ids));
      } 
      if (this.transaction_ids) {
        this.$set(filters, "transaction_ids", JSON.stringify(this.transaction_ids));
      }
 
      this.$store
        .dispatch(EXPORT_PDF, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "workmanship_pdf_list" + day + ".pdf";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    filterResult() {
      localStorage.setItem(
        WORKMANSHIP_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        WORKMANSHIP_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);

      this.tempFilters = filters;
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort); 

      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      }
      if (this.order_ids) {
        this.$set(filters, "order_ids", JSON.stringify(this.order_ids));
      } 
      if (this.transaction_ids) {
        this.$set(filters, "transaction_ids", JSON.stringify(this.transaction_ids));
      }

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    orderFilter(param){      
      let params=''; 
      if(param.team_id){
        params += '&team_id='+JSON.stringify(param.team_id);
      }
      if(param.company_id ){
        params += '&company_id='+JSON.stringify(param.company_id);
      }
        this.$store
          .dispatch(GET_ITEMS, {
            url: "api/report/order-list-packing?"+params, 
          })
          .then((result) => { 
            if (result.status) {
              this.orderOptions = result.data
            } else {
              console.log({ error: result });
            }
          });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          order_ids: [],
          supplier_ids: [],
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });  
    },
    setup() {
      let self = this,
        promises = [];
   
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-list/workmanship",
        })
      ); 

      // promises.push(
      //   this.$store.dispatch(GET_ITEMS, {
      //     url: "api/orders/expenses/details/expenses",
      //   })
      // ); 
 
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expenses",
        })
      ); 


      Promise.all(promises).then((results) => {
         
        if (results[0].status) {
          self.orderOptions = results[0].data;
        } 

         if (results[1].status) {
          self.supplierOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }

        if (results[2].status) { 
          let temp = results[2].data;  
          let arrTransaction = [];
          temp.forEach((element) => {
            if(element.expense_category == 1){
              let name = element.translations[0].name
              let id = element.translations[0].id
              arrTransaction.push({
                id:id,
                name:name
              })
            }
          })
 
          this.transactionOptions = self.convertArrayToObjectByKey(
            arrTransaction,
            "id",
            "name"
          );
        } 
        // if (results[2].status) {
        //   const nameMapping = {
        //       dikim: "Dikim",
        //       kesim: "Kesim",
        //       utu_paket: "Ütü Paket"
        //   };
        //   let options = this.convertArrayToObjectByKeyRevize(results[2].data, "id", "name");
        //   const { byId, byIndex } = this.transformData(options,nameMapping);
        //   self.transactionOptions = byId;
        // }
      });
    }, 
    onContextMenu(event) {
      event.preventDefault();
    },
    convertArrayToObjectByKeyRevize(obj, idKey, nameKey) {
      return Object.keys(obj).map(key => ({
          [idKey]: obj[key] ,
          [nameKey]: key
      }));
    },
    transformData(array,mapping) {
      let byId = {};
      let byIndex = {};

      array.forEach((item, index) => {
          byId[item.id] = mapping[item.name] || item.name;
          byIndex[index] = item;
      });

      return { byId, byIndex };
  }
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Kesim Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      WORKMANSHIP_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(WORKMANSHIP_REPORT_END_DATE_STORAGE_NAME)
        )
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      WORKMANSHIP_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(WORKMANSHIP_REPORT_START_DATE_STORAGE_NAME)
        )
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.beforeFilterResult();
    },
    order() {
      this.beforeFilterResult();
    }, 
    "company_id"(value){ 
      this.orderFilter({'company_id':value})      
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>
